import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ExcelUploader from './ExcelUploader';
import ImageUploader from './ImageUploader';
import Navbar from './Navbar';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Navigate to="/excel-upload" />} />
        <Route path="/excel-upload" element={<ExcelUploader />} />
        <Route path="/image-upload" element={<ImageUploader />} />
      </Routes>
    </Router>
  );
}

export default App;

// import React from 'react';
// import ExcelUploader from './ExcelUploader';

// function App() {
//   return (
//     <div className="App">
//       <ExcelUploader />
//     </div>
//   );
// }

// export default App;