import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';

const isLocalMode = process.env.REACT_APP_LOCAL_MODE === 'true';
const apiUrl = isLocalMode ? process.env.REACT_APP_LOCAL_API_URL : process.env.REACT_APP_PROD_API_URL;

const slugify = (text) => {
  const turkishToEnglishMap = {
    'ç': 'c', 'ğ': 'g', 'ı': 'i', 'ö': 'o', 'ş': 's', 'ü': 'u',
    'Ç': 'C', 'Ğ': 'G', 'İ': 'I', 'Ö': 'O', 'Ş': 'S', 'Ü': 'U'
  };

  const replacedText = text.replace(/[\u00C0-\u017F]/g, (c) => turkishToEnglishMap[c] || c);
  return replacedText
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
};

const ExcelUploader = () => {
  const [data, setData] = useState([]);
  const [projectName, setProjectName] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const transformData = (rowData) => {
    return {
      ...rowData,
      PriceNote: rowData['Price Note'],
    };
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const arrayBuffer = e.target.result;
      const workbook = XLSX.read(arrayBuffer, { type: 'array' });

      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      const transformedData = jsonData.map(transformData);
      setData(transformedData);

      const projectWorksheetName = workbook.SheetNames[1];
      const projectWorksheet = workbook.Sheets[projectWorksheetName];
      const projectData = XLSX.utils.sheet_to_json(projectWorksheet, { header: 1 });
      const projectNameRaw = projectData[1][0];
      const projectNameSlug = slugify(projectNameRaw);
      setProjectName(projectNameSlug);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleProcess = (event) => {
    event.preventDefault(); // Formun varsayılan submit işlemini engelle
    setIsProcessing(true);
    setError(null);
    setSuccessMessage(null);
  
    axios.post(`${apiUrl}`, {
      data: JSON.stringify(data),
      projectName
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      setSuccessMessage('Processing completed successfully!');
      setIsProcessing(false);  // Ensure you stop the processing state
      setData([]);  // Clear the data to avoid re-triggering the process
    })
    .catch((error) => {
      console.error('Error during axios request:', error);
      setError('Error processing template. Please try again.');
    })
    .finally(() => {
      setIsProcessing(false);  // Make sure to stop processing in all cases
    });
  };

  return (
    <div>
      <h1>Excel Dosyası Yükleyin</h1>
      
      <input 
        type="file" 
        accept=".xlsx, .xls" 
        onChange={handleFileUpload} 
        disabled={isProcessing}
      />

      {isProcessing && <p>Processing, please wait...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}

      {data.length > 0 && (
        <button type="button" onClick={handleProcess}>
          İşlemi Başlat
        </button>
      )}

      {data.length > 0 && (
        <div>
          <h3>Excel İçeriği</h3>
          <table border="1">
            <thead>
              <tr>
                {Object.keys(data[0]).map((key, index) => (
                  <th key={index}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {Object.values(row).map((value, colIndex) => (
                    <td key={colIndex}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ExcelUploader;