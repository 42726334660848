import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ImageUploader() {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [allImages, setAllImages] = useState([]); // Tüm resimler burada tutulacak
  const [images, setImages] = useState([]); // Son 20 resim burada tutulacak
  const [searchTerm, setSearchTerm] = useState('');

  // API base URL'i çevre değişkeninden alıyoruz
    const isLocalMode = process.env.REACT_APP_LOCAL_MODE === 'true';
    const apiUrl = isLocalMode ? process.env.REACT_APP_LOCAL_API_URL : process.env.REACT_APP_PROD_API_URL;
    const pathUrl = isLocalMode ? process.env.REACT_APP_PATH_LOCAL_MODE : process.env.REACT_APP_PATH_PROD_MODE;


    console.log(apiUrl);
    console.log(pathUrl);
    console.log(process.env.PATH_LOCAL_MODE);
  // Birden fazla resim seçimi
  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  // Birden fazla resim yükleme
  const handleUpload = () => {
    if (selectedFiles.length === 0) return;
  
    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('images[]', selectedFiles[i]);  // images[] şeklinde ekleyin
    }
  
    axios
      .post(`${apiUrl}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        alert(response.data.message);
        fetchImages(); // Resimleri güncelle
      })
      .catch((error) => {
        console.error('Hata:', error);
      });
  };
  // Resim silme işlevi
  const handleDelete = (filename) => {
    axios
      .request({
        url: `${apiUrl}`, // URL silme işlemi için değiştirilebilir
        method: 'DELETE',
        data: { filename }, // Gövde içinde filename gönderiliyor
      })
      .then((response) => {
        alert(response.data.message);
        fetchImages(); // Resimleri güncelle
      })
      .catch((error) => {
        console.error('Hata:', error);
      });
  };

  // Resimleri alma
  const fetchImages = () => {
    axios
      .get(`${apiUrl}/images`)
      .then((response) => {
        const files = response.data;

        // Tüm resimleri ters sıraya çevir (son yüklenenler en başta)
        const sortedFiles = files.reverse();

        setAllImages(sortedFiles); // Tüm resimleri kaydet

        // Son 20 resmi al
        const latestFiles = sortedFiles.slice(0, 20);
        setImages(latestFiles);
      })
      .catch((error) => {
        console.error('Hata:', error);
      });
  };

  useEffect(() => {
    fetchImages();
  }, []);

  // Arama filtresi - Tüm resimler arasında arama yapıyoruz
  const filteredImages = allImages.filter((image) =>
    image.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Ekranda gösterilecek resimler: Arama varsa filtrelenmiş sonuçlar, yoksa son 20 resim
  const imagesToDisplay = searchTerm ? filteredImages : images;

  return (
    <div style={{ padding: '20px' }}>
      <h1>Birden Fazla Resim Yükleme ve Listeleme</h1>

      <div>
        <input type="file" onChange={handleFileChange} accept="image/*" multiple />
        <button onClick={handleUpload}>Yükle</button>
      </div>

      <div>
        <input
          type="text"
          placeholder="Ara..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {imagesToDisplay.map((image) => (
          <div key={image} style={{ margin: '10px' }}>
            <img
              src={`${pathUrl}/uploads/${image}`}
              alt={image}
              style={{ width: '150px', height: '150px', objectFit: 'cover' }}
            />
            <p>{image}</p>
            <button onClick={() => handleDelete(image)}>Sil</button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ImageUploader;